var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "global-uploader" } },
    [
      _c(
        "uploader",
        {
          ref: "uploader",
          staticClass: "uploader-app",
          attrs: { options: _vm.options, autoStart: false },
          on: {
            "file-added": _vm.onFileAdded,
            "file-success": _vm.onFileSuccess,
            "file-progress": _vm.onFileProgress,
            "file-error": _vm.onFileError,
          },
        },
        [
          _c("uploader-unsupport"),
          _c(
            "uploader-btn",
            {
              ref: "uploadBtn",
              attrs: { id: "global-uploader-btn", attrs: _vm.attrs },
            },
            [_vm._v("选择文件")]
          ),
          _c("uploaderlist", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.panelShow,
                expression: "panelShow",
              },
            ],
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return _c(
                    "div",
                    {
                      staticClass: "file-panel",
                      class: { collapse: _vm.collapse },
                    },
                    [
                      _c("div", { staticClass: "file-title" }, [
                        _c("h3", [_vm._v("文件列表")]),
                        _c(
                          "div",
                          { staticClass: "operate" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  title: _vm.collapse ? "展开" : "折叠",
                                },
                                on: { click: _vm.fileListShow },
                              },
                              [
                                _c("i", {
                                  class: _vm.collapse
                                    ? "el-icon-arrow-down"
                                    : "el-icon-arrow-up",
                                  staticStyle: {
                                    "font-size": "16px",
                                    color: "#0a001f",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", title: "关闭" },
                                on: { click: _vm.close },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-close",
                                  staticStyle: {
                                    "font-size": "16px",
                                    color: "#0a001f",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "ul",
                        { staticClass: "file-list" },
                        [
                          _vm._l(props.fileList, function (file) {
                            return _c(
                              "li",
                              { key: file.id },
                              [
                                _c("uploaderfile", {
                                  ref: "files",
                                  refInFor: true,
                                  class: "file_" + file.id,
                                  attrs: { file: file, list: true },
                                }),
                              ],
                              1
                            )
                          }),
                          !props.fileList.length
                            ? _c("div", { staticClass: "no-file" }, [
                                _c("i", {
                                  staticClass: "iconfont icon-empty-file",
                                }),
                                _vm._v(" 暂无待上传文件"),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }