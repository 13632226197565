<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    append-to-body
    :close-on-click-modal="false"
    top="7vh"
    width='850px'
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span style="font-size: 16px;">我的视频库</span>
    </div>
    <el-row :gutter="12">
      <el-col :span="16">
        <div style="display: flex;align-items: center">
        <el-input
          style="flex: 5;"
          placeholder="输入搜索视频标题"
          v-model.trim="inputVal"
          @keyup.enter.native="queryVideoList"
        >
        </el-input>
        <el-date-picker
          style="flex: 12; margin-left: 10px"
          @change="$forceUpdate()"
          @blur="$forceUpdate()"
          v-model="searchTime"
          type="datetimerange"
          start-placeholder="创建开始时间"
          end-placeholder="创建结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00']"
        ></el-date-picker>
        </div>
      </el-col>

      <el-col :span="8" style="margin-top: 3px">
        <el-button type="primary" size="mini" @click="search" class="tb-button">查 询</el-button>
        <el-button type="primary" size="mini" @click="reset" class="tb-button">重 置</el-button>
        <el-button type="primary" size="mini" @click="openUploader">上传文件</el-button>
      </el-col>
    </el-row>
    <el-table
      style="margin-top: 15px;"
      :data="dataList"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="文件标题" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="来源" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.videoInfo.type }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" v-if="originVideo.id !== scope.row.id"
                     @click="chooseStudent(scope.row)">选择
          </el-button>
          <el-button type="success" size="mini" v-if="originVideo.id === scope.row.id">已选择</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
    <global-uploader></global-uploader>
  </el-dialog>
</template>

<script>
import {videolistMy} from '@/api/video/videomanages'
import Bus from "../../../views/video/js/bus";
import GlobalUploader from "../../../views/video/component/globalUploader";

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    originVideo: {
      default: {},
      type: Object
    },
  },
  components:{
    GlobalUploader
  },
  data() {
    return {
      inputVal: '',
      searchTime: [],
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      }
    }
  },
  methods: {
    openUploader() {
      Bus.$emit('openUploader', {

      })
    },
    chooseStudent(row) {
      this.$confirm('确定选择当前视频吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.$emit("chooseVideo", row)
        this.close()
      })
        .catch(() => {
          return false
        })
    },
    openDialog() {
      this.inputVal = ''
      this.searchTime = []
      this.queryVideoList()
    },

    // 获取视频管理列表
    async queryVideoList() {
      const res = await videolistMy({
        page: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        minTime:this.searchTime.length === 0 ? '' : this.searchTime[0],
        maxTime:this.searchTime.length === 0 ? '' : this.searchTime[1],
        title: this.inputVal,
        type: '视频',
      })
      this.dataList = res.data.list
      this.pagination.total = res.data.total
    },

    close() {
      this.$emit('close')
    },

    search() {
      this.pagination.currentPage = 1
      this.queryVideoList()
    },
    reset() {
      this.inputVal = ''
      this.searchTime = []
    },
    // 分页
    handleSizeChange(val) {
      this.pagination.pageSize = val
      this.queryVideoList()
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.queryVideoList()
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
@import "../../../assets/font/iconfont.css";

.centerCls {
  display: flex;
  align-items: center
}

p {
  line-height: 30px;
}
</style>
