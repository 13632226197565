var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "append-to-body": "",
        "close-on-click-modal": false,
        top: "7vh",
        width: "850px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { staticStyle: { "font-size": "16px" } }, [
            _vm._v("我的视频库"),
          ]),
        ]
      ),
      _c(
        "el-row",
        { attrs: { gutter: 12 } },
        [
          _c("el-col", { attrs: { span: 16 } }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("el-input", {
                  staticStyle: { flex: "5" },
                  attrs: { placeholder: "输入搜索视频标题" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.queryVideoList.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.inputVal,
                    callback: function ($$v) {
                      _vm.inputVal = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "inputVal",
                  },
                }),
                _c("el-date-picker", {
                  staticStyle: { flex: "12", "margin-left": "10px" },
                  attrs: {
                    type: "datetimerange",
                    "start-placeholder": "创建开始时间",
                    "end-placeholder": "创建结束时间",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    "default-time": ["00:00:00"],
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$forceUpdate()
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                  model: {
                    value: _vm.searchTime,
                    callback: function ($$v) {
                      _vm.searchTime = $$v
                    },
                    expression: "searchTime",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-col",
            { staticStyle: { "margin-top": "3px" }, attrs: { span: 8 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.search },
                },
                [_vm._v("查 询")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.reset },
                },
                [_vm._v("重 置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.openUploader },
                },
                [_vm._v("上传文件")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { "margin-top": "15px" },
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "文件标题", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.title))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "创建时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.createTime))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "来源", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.videoInfo.type))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.originVideo.id !== scope.row.id
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.chooseStudent(scope.row)
                              },
                            },
                          },
                          [_vm._v("选择 ")]
                        )
                      : _vm._e(),
                    _vm.originVideo.id === scope.row.id
                      ? _c(
                          "el-button",
                          { attrs: { type: "success", size: "mini" } },
                          [_vm._v("已选择")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c("global-uploader"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }