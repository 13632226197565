var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "iframes" },
    [
      _c("iframe", {
        ref: "iframes",
        staticClass: "iframes",
        attrs: {
          id: "pptiframe",
          allowfullscreen: "true",
          src: _vm.iframeWinPaht,
          frameborder: "0",
        },
      }),
      _c("MyVideos", {
        attrs: { show: _vm.showMyVideos, originVideo: _vm.originVideo },
        on: {
          close: () => (_vm.showMyVideos = false),
          chooseVideo: _vm.chooseVideo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }