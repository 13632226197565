<template>
  <div class="iframes">
  <iframe id='pptiframe'  allowfullscreen='true' class="iframes" :src="iframeWinPaht" frameborder="0" ref="iframes" />
  <!-- <iframe
    allowfullscreen='true'
    class="iframes"
    src="http://localhost:8081/ppt/#/edit"
    frameborder="0"
    ref="iframes"
  /> -->
    <MyVideos 
      @close='()=>showMyVideos=false' 
      :show='showMyVideos' 
      :originVideo='originVideo' 
      @chooseVideo='chooseVideo'
    />
  </div>
</template>
<script>
import MyVideos from '@/components/publicComponents/chooseVideo/index.vue';
import {getVideoUrl} from '@/api/video/videomanages'

export default {
  name:"Edits",
  components:{
    MyVideos,
  },
  data() {
    return {
      iframeWinPaht: process.env.VUE_APP_PPT_IDE + "edit",
      pptData: {
        code: '可达鸭编程-'+localStorage.getItem('userName')+'|'+localStorage.getItem('pptToken'),
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem("pptToken"),
        platform: localStorage.getItem("platform"), //id 1	merchant	可达鸭商户平台 id 2	tms	可达鸭平台
        currentTaskId: "",
        resourceId: "",
        courseId: "",
        courseName: "",
        unitId: "",
        unitName: "",
        sort: 0,

        taskList: [], //编辑时可为空
      },
      showMyVideos: false,
      originVideo: {}
    };
  },
  mounted() {
    window.addEventListener("message", this.handleMessage, true);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleMessage, true);
  },
  created() {
    this.initData();
  },
  methods: {
    // 我的视频库
    chooseVideo(row){
      let pptDom = document.getElementById('pptiframe').contentWindow
      getVideoUrl({fileId:row.id}).then(res=>{
        const data = {
          data: {
            ...row,
            id: row.id,
            // baseUrl: (location.origin.indexOf('localhost')? location.origin : process.env.VUE_APP_PREVIEW_ADDRESS ) + '/polyv/index.html?id='+row.id,
          },
          type: 'selectVideo'
        }
        if(res.code === 0){
          data.data.type = res.data.type;
          if(res.data.type=='bjy'){
            data.data.baseUrl = res.data.play;
          } else {
            data.data.baseUrl =  (location.origin.indexOf('localhost')? location.origin : process.env.VUE_APP_PREVIEW_ADDRESS ) + '/polyv/index.html';
          }
          pptDom.postMessage(data, "*");
        } else {
          this.$message.warning(res.msg);
        }
      })
    },
    initData() {
      const currentCourseInfo = JSON.parse(
        localStorage.getItem("currentCourseInfo")
      );
      const currentTaskInfo = JSON.parse(
        localStorage.getItem("currentTaskInfo")
      );
      const data = {
        code: '可达鸭编程-'+localStorage.getItem('userName')+'|'+localStorage.getItem('pptToken'),
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem("token_manage"),
        content: this.$route.query.content,
        platform: localStorage.getItem("platform"), //id 1	merchant	可达鸭商户平台 id 2	tms	可达鸭平台
        currentTaskId: this.$route.query.id,
        currentTaskName: this.$route.query.title,
        resourceId: this.$route.query.sourceId,
        courseId: this.$route.query.courseId,
        courseName: this.$route.query.courseName,
        unitId:this.$route.query.courseUnitId,
        unitName: this.$route.query.courseUnitTitle,
        sort: this.$route.query.sort,
        name: localStorage.getItem('name'),
        roles: localStorage.getItem('perms').indexOf('ppt:manage')>=0 ? 'manage': 
                localStorage.getItem('perms').indexOf('ppt:edit')>=0 ? 'edit':'',
        taskList: [{}], //编辑时可为空,
      };
      this.pptData = data;
    },
    handleMessage(e) {
      let pptDom = document.getElementById('pptiframe').contentWindow
      switch (e.data.type) {
        case "setUseWareId":
          break;
        case "getData":
          pptDom.postMessage(
            { data: this.pptData, type: "initData" },
            "*"
          );
          break;
        case "myVideos":
          this.showMyVideos = e.data.data.value;
          this.$forceUpdate();
          break;
        case "getPptVideoInfo":
           getVideoUrl({fileId:e.data.data.id.split('-')[1]}).then(res=>{
              pptDom.postMessage(
            { data: {
              ...res,
              id:e.data.data.id,
              mode:'edit',
              code: '可达鸭编程-'+localStorage.getItem('userName')+'|'+localStorage.getItem('pptToken'),
              }, type: "returnPptVideoInfo" },
            "*"
            )
             
           })
             
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped lang='scss'>
.iframes {
  width: 100%;
  height: 100%;
  min-height: 600px;
  overflow: hidden;
}
</style>